import { Link } from 'gatsby'
import * as React from 'react'

const CtaArticle = () => {
  return (
    <div className='py-5 container-fluid bg-primary shadow rounded'>
      <div className='row justify-content-center'>
        <div className='col px-5'>
          <p className='display-6 text-white'>Get in touch</p>
          <p className='my-3'>
            Schedule a callback now and we'll get back to you as soon as
            possible.
          </p>
          <Link to={'/contact'}>
            <button className='btn-lg btn-secondary shadow mt-3'>
              Schedule Call
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CtaArticle
