import * as React from 'react'
import Article from '../components/article'
import Seo from '../components/seo'
import CtaArticle from '../components/ctaArticle'

const LastingPowerOfAttorney = () => {
  return (
    <>
      <Seo
        pageTitle={'Lasting Power of Attorney'}
        pageDescription={` A Lasting Power of Attorney (LPA) is a powerful
          document, where you can stipulate who is to act on your behalf if you
          are unable to do so yourself. There are two parts to the document, and
          you can make the decision today on whom your trusted individuals
          (attorneys) would be in event of you
          losing mental capacity or even due
          ill-health or of an accident.`}
      />
      <Article>
        <h1>Lasting Power of Attorney</h1>
        <p>
          A <strong>Lasting Power of Attorney</strong> (LPA) is a powerful
          document, where you can stipulate who is to act on your behalf if you
          are unable to do so yourself. There are two parts to the document, and
          you can make the decision today on whom your trusted individuals
          (attorneys) would be in event of you{' '}
          <strong>losing mental capacity</strong> or even due to{' '}
          <strong>ill-health</strong> or of an <strong>accident</strong>.
        </p>
        <p className='fs-3 lead'>
          If you were unable to make your own decisions due to the above, what
          would you do about your...
        </p>

        <h2>
          Health & Welfare <span className='fw-light'> ?</span>
        </h2>
        <ul>
          <li>Decisions on whether you should be moved to a care-home</li>
          <li>Determining on medical care</li>
          <li>
            Making decisions on what you wear, what you eat, when you wash
          </li>
          <li>
            Decisions on Life-Sustaining treatment (surgery, medication, organ
            transplant)
          </li>
        </ul>

        <h2>
          Property & Financial Affairs<span className='fw-light'> ?</span>
        </h2>
        <ul>
          <li>Assistance with Bank Accounts & Investments</li>
          <li>Maintaining Bills</li>
          <li>Buying & Selling Property</li>
        </ul>

        <p>
          This day and age without a registered LPA your family or loved ones
          would not be able to make the simplest decisions for you should you
          lose capacity. Instead, they would need to apply to the Court of
          Protection for a Deputyship which is a costly and time-consuming
          process. It may also then be too late for your loved ones to act by
          the time the application has been agreed by the Courts.
        </p>
        <p>
          Anyone can apply via the Court of Protection, even someone that you
          did not want to get involved with any decision making regarding your
          financial affairs or your health & wellbeing.
        </p>
        <p>
          Decide who would be responsible for your actions if you were unable
          to, by making a Lasting Power of Attorney today with our help.
        </p>
        <CtaArticle />
      </Article>
    </>
  )
}

export default LastingPowerOfAttorney
